import React, { Component } from 'react';
import Cabecalho from '../componentes/Cabecalho';
import Rodape from '../componentes/Rodape';
import { firestore } from './../firebase-config';
import { Link } from 'react-router-dom';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "react-slick";
import { id_firebase, ids_firebase, href_revenda  } from '../content-site';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import XML from '../xmlFile';

class Estoque extends Component {
    constructor(props) {
        super(props);

        this.state = {
            veiculos: [],
            veiculosComplete: [],
            countVeiculos: 0,
            instagramData: [],
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
            revenda: {},
            revendaPissarelli: {},
            bannerData: []
        }
        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.mounted = true;

        firestore.collection("revenda").doc(id_firebase)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.setState({
                        revenda: doc.data(),
                    });
                } else {
                    this.setState({
                        revenda: {
                            ativo: 0,
                        }
                    });
                }
            }).catch(function (error) {
            });

        const allVehicles = [];
        let completedQueries = 0;
        

        ids_firebase.forEach((id, index) => {
            const revendaDocRef = firestore.collection("revenda").doc(id);

            revendaDocRef.collection("veiculos")
                .where("veremovido", "==", 0)
                .where("vevendido", "==", 0)
                .get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        var vehicle = doc.data();
                        vehicle.id = doc.id;
                        vehicle.href_revenda = href_revenda[index]; // Adiciona a propriedade href_revenda
                        if (vehicle["veexibir_site"] !== false) {
                            allVehicles.push(vehicle);
                        }
                        
                    });

                    completedQueries++;

                    if (this.mounted && completedQueries === ids_firebase.length) {
                        allVehicles.sort((a, b) => a.vevalor - b.vevalor);

                        this.setState({
                            veiculos: allVehicles,
                            veiculosComplete: allVehicles
                        });
                    }
                    //XML()
                })
                .catch(error => {
                    console.error("Error getting documents: ", error);
                });

            revendaDocRef.collection("imagem_destaque").orderBy("created_at", "desc")
                .onSnapshot(querySnapshot => {
                    var items = [];
                    querySnapshot.forEach(doc => {
                        var document = doc.data();
                        document.id = doc.id;
                        items.push(document);
                    });
                    if (this.mounted) {
                        this.setState(prevState => ({
                            instagramData: [...prevState.instagramData, ...items]
                        }));
                    }
                });

            revendaDocRef.collection("banner_site").orderBy("created_at", "desc")
                .onSnapshot(querySnapshot => {
                    var items = [];
                    querySnapshot.forEach(doc => {
                        var document = doc.data();
                        document.id = doc.id;
                        if (document.imagem_url !== undefined && document.imagem_url.trim().length > 0) {
                            items.push(document.imagem_url);
                        }
                    });
                    if (this.mounted) {
                        this.setState(prevState => ({
                            bannerData: [...prevState.bannerData, ...items]
                        }));
                    }
                });
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        let filtro = this.state.buscaVeiculo.toLowerCase();
        let tipoVeiculo = this.state.tipoVeiculo;

        let veiculosFilter = this.state.veiculosComplete.filter(function (veiculo) {
            let descricaoVeiculo = veiculo.vefipe_marca.toLowerCase() + " " + veiculo.vefipe_name.toLowerCase();
            if (tipoVeiculo === 'novos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem <= 100;
            }
            if (tipoVeiculo === 'seminovos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem > 100;
            }
            return descricaoVeiculo.includes(filtro);
        });

        this.setState({
            veiculos: veiculosFilter
        });

    }

    handleClick(event) {
        this.setState({
            veiculos: this.state.veiculosComplete,
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
        });
    }

    render() {
        const {
            veiculos,
            instagramData,
            tipoVeiculo,
            bannerData
        } = this.state;

        if (this.state.revenda.ativo === 0) {
            return (
                <div style={{ textAlign: "center" }}>
                    <h1>Site em manutenção.</h1>
                    <h3>Contate o suporte para mais detalhes (suporte@intermedioveiculos.com.br)</h3>
                </div>
            )
        }

        return (
            <div>
                <Cabecalho revenda={this.state.revenda} />

                <section className="product-listing page-section-ptb" style={{ paddingTop: "10px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <ValidatorForm id="contactForm" className="s-form wow zoomInUp" ref="form" onSubmit={this.handleSubmit} >
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="col-lg-6">
                                                <TextValidator placeholder="Ex.: Fiat Palio"
                                                    label="Encontre seu veículo"
                                                    onChange={this.handleChange}
                                                    name="buscaVeiculo"
                                                    value={this.state.buscaVeiculo}
                                                    className="form-ui-bootstrap" />

                                            </div>

                                            <div className="col-lg-3" style={{ paddingTop: "44px" }}>
                                                <SelectValidator
                                                    value={tipoVeiculo}
                                                    name="tipoVeiculo"
                                                    className="form-ui-bootstrap select-busca-veiculos"
                                                    onChange={this.handleChange}>
                                                    <MenuItem value="todos">Todos</MenuItem>
                                                    <MenuItem value="novos">0 Km</MenuItem>
                                                    <MenuItem value="seminovos">Seminovos</MenuItem>
                                                </SelectValidator>
                                            </div>

                                            <div className="col-lg-3 text-center" style={{ paddingTop: "30px" }}>
                                                <button type="submit" className="btn m-btn">BUSCAR VEÍCULOS<span className="fa fa-angle-right"></span></button><br />
                                                <p className="linkLimparBusca text-center" onClick={this.handleClick} style={{ color: "#362e88" }}>LIMPAR BUSCA</p>
                                            </div>
                                        </div>
                                    </div>
                                </ValidatorForm>

                                {/*<div className='bannerEstoque'>
                                    <img src='../images/bannerEstoque.jpeg' alt='Em breve!'/>
                                </div>*/}

                              <div className="row">
                                    {Object.keys(veiculos)
                                        .map(key => {
                                            return (
                                                <div className="col-lg-4" data-wow-delay="0.5s" key={veiculos[key].id}>
                                                    <Link to={`/${veiculos[key].href_revenda}/` + veiculos[key].id}>
                                                        <div className="car-item gray-bg text-center">
                                                            <div className="car-image">
                                                                {
                                                                    veiculos[key].imagens.length > 0 ?
                                                                        veiculos[key].imagens[0].fileThumb !== undefined ?
                                                                            <img className="img-fluid" src={veiculos[key].imagens[0].fileThumb} alt=""></img>
                                                                            :
                                                                            <img className="img-fluid" src={veiculos[key].imagens[0].fileUrl} alt=""></img>
                                                                        : <img className="img-fluid" src="/images/car_default.png" alt='imagem veículo'></img>
                                                                }
                                                                <div className="car-overlay-banner botaoShare-box"></div>
                                                            </div>
                                                            <div className="car-list">
                                                                <ul className="list-inline">
                                                                    <li><i className="fa fa-registered"></i>{this.state.veiculos[key].veano_fabricacao}/{this.state.vefipe_ano_modelo}</li>
                                                                    <li><i className="fa fa-cog"></i>{formatCambio(veiculos[key].vecambio)}</li>
                                                                    <li><i className="fa fa-dashboard"></i>{veiculos[key].vequilometragem} KM</li>
                                                                </ul>
                                                            </div>
                                                            <div className="car-content">
                                                                <span style={{ color: "#34383d" }}>{veiculos[key].veano_fabricacao}/{veiculos[key].vefipe_ano_modelo}</span>
                                                                <Link to={`/${veiculos[key].href_revenda}/` + veiculos[key].id}>{veiculos[key].vefipe_marca} {veiculos[key].vefipe_name}</Link>
                                                                <div className="separator"></div>
                                                                <div className="price">
                                                                    <span className="new-price">{formatMoney(veiculos[key].vevalor)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <hr className="gray"></hr>

                <Rodape revenda={this.state.revenda} />
            </div>
        )
    }
}

function formatMoney(value) {
    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

    places = !isNaN(places = Math.abs(places)) ? places : 2;
    symbol = symbol !== undefined ? symbol : "$";
    thousand = thousand || ",";
    decimal = decimal || ".";
    var number = value,
        negative = number < 0 ? "-" : "",
        i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatCambio(value) {
    if (value === 'A') {
        return "Automático";
    } else {
        return "Manual";
    }
}

export default Estoque
