import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import Inicio from './pages/Inicio';
import Estoque from './pages/Estoque';
import Contato from './pages/Contato';
import Veiculos from './pages/Veiculos';
import Financiamento from './pages/Financiamento';
import VendaSeuVeiculo from './pages/VendaSeuVeiculo';
import VeiculosKar from './revendas/Veiculos_Kar';
import VeiculosCanever from './revendas/Veiculos_Canever';
import VeiculosDsMotors from './revendas/Veiculos_Dsmotors';
import VeiculosGramkar from './revendas/Veiculos_Gramkar';
import VeiculosLucas from './revendas/Veiculos_Lucas';
import VeiculosMeA from './revendas/Veiculos_M&A';
import Veiculos_Garage from './revendas/Veiculos_Garage';
import VeiculosMaverick from './revendas/Veiculos_Maverick';
import VeiculosDcar from './revendas/Veiculos_Dcar';
import VeiculosTops from './revendas/Veiculos_Tops';
import VeiculosAmbar from './revendas/Veiculos_Ambar';




class App extends Component {
  render(){
    return (
      <Router>
          <Switch>  
              <Route path='/' exact component={Inicio} />  
              <Route path='/veiculos/:idVeiculo' component={Veiculos} />
              <Route path='/veiculo_kar/:idVeiculo' component={VeiculosKar}/>
              <Route path='/veiculo_canever/:idVeiculo' component={VeiculosCanever}/>
              <Route path='/veiculo_dsmotors/:idVeiculo' component={VeiculosDsMotors}/>
              <Route path='/veiculo_gramkar/:idVeiculo' component={VeiculosGramkar}/>
              <Route path='/veiculo_lucas/:idVeiculo' component={VeiculosLucas}/>
              <Route path='/veiculo_mea/:idVeiculo' component={VeiculosMeA}/>
              <Route path='/veiculo_garage/:idVeiculo' component={Veiculos_Garage}/>
              <Route path='/veiculo_dcar/:idVeiculo' component={VeiculosDcar}/>
              <Route path='/veiculo_tops/:idVeiculo' component={VeiculosTops}/>
              <Route path='/veiculo_ambar/:idVeiculo' component={VeiculosAmbar}/>
              <Route path='/veiculo_maverick/:idVeiculo' component={VeiculosMaverick}/>
              <Route path='/contato' component={Contato} />                  
              <Route path='/financiamento' component={Financiamento} />                  
              <Route path='/estoque' component={Estoque} />                  
              <Route path='/vendaseuveiculo' component={VendaSeuVeiculo} />         
              <Route path='/:idempresa' component={Inicio} />         
          </Switch> 
      </Router>
    );
  }
}

export default App;
